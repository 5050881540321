body, h1, h2, h3, h4, h5, h6, p, blockquote, pre, dl, dd, ol, ul, form, fieldset, legend, figure, table, th, td, caption, hr {
    margin: 0;
    padding: 0;
}

.hidden{
  display: none !important;
}

strong{
  font-weight: 600;
}
.capital{
  text-transform: capitalize;
}
.uppercase{
  text-transform: uppercase;
}
*:focus {
    outline: none;
}
h2{
  font: 600 24pt/24pt sans-serif;
  color: #1083ff;
  letter-spacing: 4pt;
  text-align: center;
}
.margin-top{
  margin-top: 40px;
}
.margin-left{
  margin-left: 40px;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}

app-patients{
  text-align: center;
}
.container{
  text-align: center;
  padding: 16px 32px;
  width: 520px;
  display: block;
  margin: auto;
  max-width: calc(100vw - 64px);
}
.logo-container{
  text-align: center;
  .client-logo{
    height: 96px;
    margin: 16px auto 32px;
    max-width: 100%;
  }
}
h1{
  font: 600 24pt/24pt sans-serif;
  color: #000;
  letter-spacing: 6pt;
  text-align: center;
  margin: 16px 0;
  text-transform: uppercase;
}
h3{
  font: 600 12pt/16pt sans-serif;
  color: #1083ff;
  margin: 32px 0;
  text-align: center;
}
p{
  font: 300 12pt/16pt sans-serif;
  color: #000;
  margin: 32px 0;
  text-align: center;
}
.flexible{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    .flexible-column{
      text-align: center;
    }
    p.flexible-column{
      margin: 3px 0;
    }
}
.flexible.between{
  justify-content: space-between;
}
.button{
  background-color: transparent;
  border: solid 1px #1083ff;
  font: 600 10pt/16pt sans-serif;
  color: #1083ff;
  padding: 8px 16px 7px;
  border-radius: 32px;
  letter-spacing: 1pt;
  width: 100%;
  text-transform: uppercase;
  display: block;
  text-decoration: none;
}
.button.full-width{
  width: 100%;
  padding: 8px 0 7px;
}
.button.active{
  box-shadow: 0 2px 4px 0 rgba(76,111,232,.6);
  background-color: #1083ff;
  border: solid 1px #1083ff;
  color: #fff;
}
a.button{
  padding: 8px 8px 7px;;
}
p.form-error{
  margin: 7px 16px 16px;
  font: 400 10pt/12pt sans-serif;
  color: #ff646c;
  text-align: right;
}
.form-input + .form-input,
div + .form-input,
label + .form-input,
.input-box-container{
  margin-top: 35px;
}
.form-input{
  padding: 4px 0;
  font: 600 12pt/14pt sans-serif;
  width: 100%;
  border-radius: 0;
  border-style: none none solid none;
  border-color: rgba(0,0,0,.12);
  border-width: 1px;
  color: rgba(0,0,0,.87);
  margin: 0;
  display: block;
  background-color: #fff;
}
.form-input.invalid{
  border-color: #ff646c;
  border-width: 2px;
}
.form-input::placeholder{
  font: 400 12pt/14pt sans-serif;
}
.form-input:focus{
  outline: 0;
  border-color: #1083ff;
  border-width: 2px;
}
.form-input.invalid::placeholder{
  color: #ff646c;
}
.disclaimer{
  margin: 12px 16px 16px;
  font: 400 10pt/14pt sans-serif;
  color: #9b9b9b;
}
re-captcha > div{
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}
.clauses-container{
  width: 100%;
  text-align: center;
  label{
    text-align: left;
    display: block;
    font: 300 12pt/16pt sans-serif;
    color: #000;
    margin: 32px 0;
    padding: 0 32px;
    input{
      margin-right: 16px;
      height: 18px;
      width: 18px;
    }
  }
}
label.checkbox-container{
  text-align: left;
  display: block;
  font: 300 12pt/16pt sans-serif;
  color: #000;
  margin: 32px 0;
  input{
    margin-right: 16px;
  }
}
.text-red{
  color: #ff646c;
}
.vidiia-logo{
  height: 48px;
  margin: 16px auto 32px;
}
.stay-safe{
  font: 600 14pt/14pt sans-serif;
  color: #9999b9;
  text-align: center;
}
.overlay{
  background-color: rgba(0,0,0,.4);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
}

.overlay i {
  color: white;
  font-size: 10vw;
  animation: rotation 1s infinite linear;
  top: calc(50% - 5vw);
  position: relative;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media only screen and (max-width: 600px) {
  .flexible{
    flex-direction: column;
    .flexible-column{
      margin-bottom: 10px;
    }

  }
  a.button{
    padding: 8px 0px 7px;;
  }
  .overlay i {
    font-size: 20vw;
    top: calc(50% - 10vw);
  }
}


.container.lateralgo{
  .flexible-column.view-results-button-container{
    display: none;
  }
}
